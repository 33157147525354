/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Nov 30, 2018, 11:54:05 AM
    Author     : Nikolay
*/

input::after {
  border-bottom: 2px solid #EAB048 !important;
}

.desktopLayout .person-button .MuiIconButton-label,
.desktopLayout .MuiBadge-root {
  min-width: 36px;
}

.views-checkoutPage-container-toolbar {
  justify-content: space-between;
}


.buttonDividedGroup {

}

.buttonDividedGroup button {
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.cart-error-confirm-dialog-actions .correctionButton {
  background-color: gray;
  border-radius: 0px !important;
  min-height: 48px;
  height: 48px;
  color: white !important;
}
.cart-error-confirm-dialog-actions {
  justify-content: space-around !important;
}

.views-filterPage-additionaToolbar button:disabled {
  color: #E3E3E3;
}

.actionButtonContainer {
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.actionButtonContainer button {
  width: 70%;
}

.shortCart-topBlock > div {
  /* position: absolute; */
    z-index: 10;
    /* margin-right: 20px; */
    /* width: 90%; */
}
.shortCart-topBlock span:nth-of-type(1) {
  z-index: 10;
  max-width: 70%;
  height: 1rem;
  overflow: visible;
  width: 70%;
  display: block;
  float: left;
  position: relative;
}

.views-filterPage-filterArea-block-badge span {
  right: -0.2rem;
  top: 5px;
}
.filters-sections-notSelected
/* .filters-sections-partialSelected, */
/* .filters-sections-fullSelected */
 {
  display: none !important;
}

.containers-shoppingTable-parts-nameCell img {
  max-width: 40px;
}

.podZakaz td {
  background-color: #D3EEF4 !important;

}

.nenabor-actions-container .plusButton, .nenabor-actions-container .shoppingCartInput {
  width: auto;
}
.nenabor-actions-container .plusButton, .nenabor-actions-container .minusButton {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}
.nenabor-actions-container .plusButton, .nenabor-actions-container .ostPlusButton:hover,
.nenabor-actions-container .plusButton, .nenabor-actions-container .ostPlusButton {
  background-color: #FFA80D;
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  white-space: nowrap;
}

.profilePage-container .form {
  margin: 0 auto;
}

.restorePassword-resultMessage-success li {
    text-align: left;
}
.restorePassword-resultMessage-success {
  color: black;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 1.2px;
}

.restorePassword-resultMessage-error {
  color: red;
}

.firstPage-inner-auto .login-form {

}
.firstPage-inner-auto .restore-pass-form .login-form div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.firstPage-inner-auto .restore-pass-form .login-form button.closeButton {
  margin-right: 5px;
}
.firstPage-inner-auto .restore-pass-form .login-form button {
  max-width: 169px;
}
.firstPage-inner-auto .restore-pass-form ul {
  padding-left: 18px;
  margin-top: 10px;
}
.firstPage-inner-auto .restore-pass-form h3 {
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 18px;
}
.firstPage-inner-auto .restore-pass-form {
  max-width: 329px;
  padding-top: 30px;
  /* padding: 0px; */
  /* padding-left: 48px;
  padding-right: 48px;
  padding-top: 29px;
  padding-bottom: 29px; */
}
.firstPage-inner-auto .form {
  margin: 0 auto;
}


.standardInput label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.shortCard-badge.postavka {
  font-size: 1.125rem;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
}
.shortCard-badge.postavka img {
  width: 24px;
  margin-left: 5px;
  margin-right: 5px;
}

.firstPage-logo {

}
.firstPage-logo-border {
  margin: 0 auto;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstPage-logo-border img {
  height: 4rem;
}

.searchBar-input div::after {
  border-bottom: 2px solid #EAB048 !important;
}

.appBar-searchField::after {
  border-bottom: none !important; /*2px solid #EAB048 !important; */
}

.views-filterPage-filterArea-singles-container img {
  height: 12px;
}

.confirmRemButton {

}

.shoppingCart-clear span {
  color: gray;
}
.shoppingCart-clear {
  margin-top: 50px;
}

.views-content-page {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 5px 25px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.standardInput input::after {
  border-bottom: 0px solid transparent;
}
.standardInput input {
  border-radius: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  background: #FFFFFF;
  color: black;
}

.bordered {
  border: 2px solid #FFA80D;
}

.backButton {
  color:#EAB048 !important;
  border: 3px solid #EAB048 !important;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  min-height: 48px;
  height: 48px;
}

.standardBage.invisible span {
  display: none;
}

.desktopLayout.containers-header-appBar svg {
  width: 36px;
}

.standardBage  span {
  background-color:#FFA80D !important;
  color: white;
}
.standardButton {
  background-color:#FFA80D !important;
  min-height: 48px;
  height: 48px;
}
.standardButton:disabled {
    background-color: lightgrey !important;
}

.activeChip span,
.activeChip svg {
  color:#EAB048 !important;
  font-weight: bold;
}
.activeChip div {
  background-color:#EAB048 !important;
}

@media (max-width: 800px) {
  .nenabor-table .ostPlusButton {

  }
  .nenabor-table th {

  }
  .nenabor-table td {

  }

}



.productList-filter-container {
  position: fixed;
  z-index: 100;
  bottom: 0px;
  top: 100px;
  minWidth: 230px;
  width: 230px;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px -2px rgba(0, 0, 0, 0.15);
  margin-left: 24px;
  font-size: 14px;
  overflow-x: hidden;
}

.productList-filter-container .views-filterPage-toolbar {
  width: 230px;
}
.productList-filter-container .views-filterPage-filterArea-singles-container div {
  max-width: 194px;

}
.productList-products-container {
  /*left: 254px;*/
  padding-left: 254px;
  width: calc(100% - 254px);
  position: absolute;
}

/*************** My Orders page *******************/
.views-orderPage-dataArea tbody tr.views-orderPage-detailOrderTable-mainRow:nth-of-type(odd) td {
    background-color: white;
}

.views-orderPage-ordersTable {
  max-width: 1000px;
  margin: 0 auto;
}

.views-orderPage-dataArea table {
  font-size: 14px;
  line-height: 59px;
}
/*************** My Orders page *******************/

/*************** Profile page *******************/
.shadowedBlock {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: black;
}
.profilePage-container-info {
  text-align: center;
  padding: 10px 0px;
  max-width: 320px;
  margin: 0 auto;
  font-weight: bold;
}
.profilePage-container .form td img {
  float: right;
  position: absolute;
  right: 50px;
  padding: 0px 5px 0px 5px;
  background-color: white;
}
.profilePage-container .form td > span  {
  color: #666666;
  font-size: 1.2rem;
}
.profilePage-container .form td > div  {

}

.profilePage-container .form  {
  text-align: left;
}
.profilePage-container .form input {
  border: none;
  box-shadow: none;
  background-color: transparent;
  margin: 0 0;
  padding: 0 0;
  color: black;
  /* font-size: 1.2rem !important; */
}
.profilePage-container .form button:hover {
  background-color: transparent;
  color: gray;
}
.profilePage-container .form button {
  background-color: transparent;
  color: gray;
  max-width: 169px;
}
.profilePage-container table {
  max-width: 1440px;
  font-size: 14px;

}

.profilePage-container-table  {
  width: 100%;
  margin: 0px;
  color: #666666;
  padding-top: 30px;
}
.profilePage-container-table tr:nth-of-type(odd) td {
  background-color: white;
}
.profilePage-container-table td:nth-of-type(2) {
  text-align: center;
  padding-right: 1rem;
}
.profilePage-container-table td {
  font-size: 1.3rem;
  padding-left: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}


.profilePage-container td {
  padding-bottom: 10px;
}
/* End ********* Profile page *******************/


/******************* Header ********************/
@media (min-width: 1024px) {
  .header-appBar-logo {
    padding-top: 10px;
  }
  .header-appBar-currencyBlock-exchangeRate {
    padding-right: 10px;
  }
  .header-appBar-currencyBlock-changeCurrency {
    padding-right: 0px;
  }
  .containers-header-appBar button {

  }
  .person-button {
    /*color: #FFA80D;*/
    margin-left: 15px !important;
  }
}

/*********** Currency change ******/
.header-appBar-currencyBlock-exchangeRate {
  color: black;
}

.header-appBar-currencyBlock-changeCurrency {

}

.header-appBar-currencyBlock-changeCurrency .active {
  color: #FFA80D;
}
/* End ***** Currency change ******/

/*********** Text menu block ******/
.header-appbar-menu {
  padding-left: .4em;
  padding-top: 10px;
}

.header-textMenu-item {
  text-decoration: none;
  color: black;
  padding-left: 3em;
}
/* End ***** Logo ******/

.containers-header-appBar button {
  color: gray;
  padding: 8px 8px;
}

.person-button {
  /*color: #FFA80D;*/
}

.person-button-menu div {
  top: 55px !important;
  border-radius: 0px !important;
}

.shoppingCart-button-badge span {
  background-color: #FFA80D;
}


.desktopLayout .header-textMenu-item.active {
  color: #FFA80D;
  font-weight: bold;
}

.containers-header-appBar {
    background-color: rgba(255,255,255,1) !important;
}

.containers-header-pngIconSize {
    width: 32px;
    height: 32px;
}
.containers-header-pngIconSize-second {
    width: 20px;
    height: 20px;

}

.containers-header-mainMenuDrawer-paper {
    overflow-x: hidden !important;
    min-width: 300px;
}

.containers-header-secondToolbar-filterButton {
    width: 100%;
    height: 24px;
    min-height: 24px  !important;
    padding: 0px  !important;
}

.containers-header-secondToolbar-search-grid {
    height: 24px;
}
.containers-header-secondToolbar-search-grid div {
    height: 26px;
}
.containers-header-secondToolbar-search-grid input {
    /*border-top: 0px none;
    border-left: none;
    border-right: none;*/
    width: 100%;
}

.containers-header-filterButton {
    font-size: 1em !important;
    padding-left: 0px  !important;
    margin: 0px;
}

.additionalToolbar {
    min-height: 17px !important;
    height: 17px;
    border-top: 1px solid lightgray;
    text-align: center;
    font-size: 14px;
}

.containers-header-additionalToolbar-currentPosition {
    font-size: 0.6em;

}

.containers-header-additionalToolbar-progress {
    width: 100%;
    position: absolute;
    left: 0px;
    height: 100%;
    top: 0px;
}

.containers-header-additionalToolbar-progress-line {
    width: 50%;
    background-color: #FFA80D;
    height: 17px;

}

.containers-header-appBar-toolbarBrandTitle {
  z-index: 100;
}

.containers-header-additionalToolbar-currentPosition-text {
  background-color: white;

}

/* End *************** Header ********************/


.priceText-currency {
  font-size: .7em;
  color: gray;
  font-weight: normal;
}

/********************* Filter ********************/
.desktopLayout .views-filterPage-filterArea-root {
  scrollbar-width: thin !important;
  overflow-y: scroll;

}
.desktopLayout .views-filterPage-filterArea-root::-webkit-scrollbar-thumb {
  background-color: gray;
}
.desktopLayout .views-filterPage-filterArea-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15) !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15) !important;
  background-color: #F5F5F5 !important;
}
.desktopLayout .views-filterPage-filterArea-root::-webkit-scrollbar {
  width: 5px !important;
  background-color: #F5F5F5 !important;
  padding-right: 0px !important;
}
.views-filterPage-filterArea-ul .views-filterPage-filterArea-block-filter-row {
  padding-left: 0px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  display: flex;
  /*align-items: flex-start;*/
}

.filter-row-checkbox {
  float:right;
  height: 18px !important;
  padding: 0px 0px !important;
}



.views-filterPage-filterArea-block-CollapsedRootListItem .filter-row-name span {
  color: #EAB048;
}

.views-filterPage-filterArea-singles-container div:hover {
  background-color: transparent;
}

.views-filterPage-filterArea-singles-container {
  align-items: flex-start;
}

.views-filterPage-filterArea-singles-container div {
  padding-left: 24px;
  padding-right: 10px;
  width: 100%;
  max-width: none !important;
}

.views-filterPage-filterArea-block-uiSubheader {
  padding-right: 0px !important;
  padding-left: 10px !important;
}
.views-filterPage-filterArea-block-filterNameDiv {
  font-size: 14px;
}

.views-filterPage-filterArea-block-CollapsedRootListItem {
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.views-filterPage-filterArea-ul .views-filterPage-filterArea-block-filter-row {
  color: #666666 !important;
}

.mobileLayout .views-filterPage-filterArea-singles-container .views-filterPage-filterArea-block-filter-row span {
  font-size: 16px !important;
  margin-left: 3px;
}
.desktopLayout .views-filterPage-filterArea-singles-container .views-filterPage-filterArea-block-filter-row .filter-row-checkbox span {
  padding-left: 3px;
}
.desktopLayout .views-filterPage-filterArea-singles-container .views-filterPage-filterArea-block-filter-row .filter-row-name {
  font-size: 12px !important;
  margin-left: 0px;
}


.views-filterPage-filterArea-block-filter-row svg {

}

.views-filterPage-filterArea-block-CollapsedRootListItem {
  padding-left: 10px !important;
}

.views-filterPage-filterArea-block-badge span {
  background-color: #FFA80D;
  color: white;

  font-size: 14px;
}

.filter-share-button {
  float: none;
  clear: both;
}
/* End *************** Filter ********************/


/************************ Cart ******************/
.mobile .containers-shoppingTable-root {
  width:100%;
  font-size: 1em;
  position: relative;
  left: 5%;
}
.mobile .containers-shoppingTable-parts-secondCell {
  font-size: 1em;
}

.containers-shoppingTable-parts-row-image {
    max-height: 120px;
    height: 120px;
    max-width: 110px;
    width: 110px;
    object-fit: contain;
}
.mobile .containers-shoppingTable-parts-row-imageCell {
  max-height: 120px;

}

.mobile .containers-shoppingTable-parts-refNum {
  padding-top: 10px;
}

.mobile .shoppingCartInput {
  top: 5px;
}
.mobile .subHeader {
  color: gray;
  font-size: 10px;
  font-weight: normal;
}


.cart-top-container {
  top: 0px;
  left: 0px;
  right: 0px;
  position: fixed;
  background-color: #E9F6FE;
  z-index: 1000;
  margin-bottom: 32px;
  padding-top: 5px;
  padding-right: 20px;
}

.cart-button-conainer {
  width: 90%;
}

.layouts-fullscreen-root .cart-button-conainer {
  width: 100%;
}

.desktopLayout .cart-top-container {


  top: 60px;
  opacity: 1;

}
/************************ Cart ******************/


/************************ Nenabor ******************/
.nenabor-container {

}
.nenabor-table {
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 15px;
}
.nenabor-table td.alignRight {
  text-align: right;
}
.nenabor-table td:nth-of-type(1) {
  text-align: left;
  padding-left: 5px;
  width: 60%;
}
.nenabor-table td:nth-last-of-type(1) {
  padding-right: 5px;
}
.nenabor-table td {
  background-color: white;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
}
.nenabor-table .shoppingCartInput input {

}

.plusButton:disabled {
  background-color: lightgray !important;
}

.nenabor-actions-container .minusButton,
.nenabor-actions-container .ostPlusButton {
  margin-top: 0px !important;
  border-radius: 0px !important;
}

.nenabor-actions-container .plusButton,
.nenabor-actions-container .ostPlusButton {

  text-shadow: none !important;
  -webkit-text-shadow: none !important;
}
.nenabor-actions-container .plusButton {
  margin-top: 0px !important;
}

.nenabor-actions-container {
  display: inline-flex;
  align-items: center;
  align-self: center;
}
/************************ Nenabor ******************/

/************************ Short Card ******************/
/***** Buttons ***/
.mobileLayout .shortCard-BottomNavigation-container .plusButton,
.mobileLayout .shortCard-BottomNavigation-container .nenaborButton {
  height: 3.125rem;
  font-size: 1.8rem;
}
.shortCard-BottomNavigation-container .plusButton {
  margin-top: .1rem;
  border-radius: 0px;
}
.shortCard-BottomNavigation-container .nenaborButton {
  width: 4.3rem;
  min-width: 4.3rem;
  max-width: 4.3rem;
  margin-top: .1rem;
  border-radius: 0px;

}

.shortCard-BottomNavigation-container .buttonPlaceholder {
  width: 4.2rem;
  min-width: 4.2rem;
  max-width: 4.2rem;
}
/* nenaborButton */
.shortCard-mobile-container .nenaborButton {
  background-color: #FFA80D !important;
}
.detailPage-container .nenaborButton,
.desktop-shortCard-container .nenaborButton {
  background-color: #ffa40066;
}
/* plusButton */
.shortCard-BottomNavigation-container .plusButton:hover,
.shortCard-mobile-container .plusButton:hover,
.shortCard-mobile-container .plusButton {
  background-color: #92BB47;
}
.desktop-shortCard-container .plusButton,
.desktopLayout .detailPage-container .plusButton {
  background-color: #95bb559e;
}
.desktop-shortCard-container .plusButton:hover,
.desktopLayout .detailPage-container .plusButton:hover {
  background-color: #95bb559e;
}
/* size item */
.shortCard-mobile-container .shortCard-sizes {
  font-size: 0.7em;
  line-height: 1.5em;
}
.desktop-shortCard-container .shortCard-sizes {
}

.shortCard-priceBlock {
  display: inline-block;
  margin-left: 5px;
  background-color: white;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

}

/* End ****************** Short Card ******************/




#login_phone {
  /*text-indent: 1ch;*/
}

#login_phone:before {
    background-color: white;
    border-right: 1ch solid white;
    content: attr(data-phone);
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    text-indent: 0;
    top: 0;
    width: 5ch;
}


.desktop-shortCard-container {
  position: relative !important;
}

.maximize .shortCart-topBlock {
  z-index: 1099 !important;
  position: relative;
  display: block;
  color: black;
}
.maximize .desktop-shortCard-container-cardRoot {
  z-index: 1099 !important;
  box-shadow: none;
}

.desktop-shortCard-container .desktop-shortCard-container-bigCard {
  width: 328px;
  height: auto;
  position: absolute !important;
  top:-5px;
  margin-right: 0px;
  right: 8px;

}

.desktop-shortCard-container .desktop-shortCard-container-bigCard .shortCardContainer {
  padding-right: 0px;
  padding-left: 32px !important;
}
.desktop-shortCard-container .desktop-shortCard-container-bigCard  {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.15), 0px 2px 1px -1px rgba(0, 0, 0, 0.15);
  border: 1px solid gray;
}

.desktop-shortCard-container-cardRoot {
  border-radius: 0px !important;
}

.desktop-shortCard-container-bigCard .desktop-shortCard-container-cardRoot {
  background-color: transparent;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.15), 0px 2px 1px -1px rgba(0, 0, 0, 0.15);
    /*box-shadow: none;*/
  /*border: 1px solid white;*/
}

.desktop-shortCard-container-bigCard {
  background-color: white;
  z-index: 1050;
}

.desktop-shortCard-container-bigCard .desktop-shortCard-container-bigCard-imageBlock-main {
  margin-left: 75px;
  position: relative;
  right: 0px;
}
.desktop-shortCard-container-bigCard .desktop-shortCard-container-bigCard-imageBlock-main-first {
  margin-left: 0px;
  position: relative;
  right: 0px;
}

.desktop-shortCard-container-bigCard-imageBlock-thumbs-first img {
  max-width: 70px;
  width: 70px;
  margin-left: 3px;
  max-height: 70px;
  height: 70px;
  object-fit: contain;
  overflow-y: scroll;
  overflow-x: hidden;
}

.desktop-shortCard-container-bigCard-detailTable tr:nth-of-type(even) td {
  background-color: #E9F6FE;
}

.desktop-shortCard-container-bigCard-detailTable tr td:nth-of-type(2){
  text-align: right;
}

.desktop-shortCard-container-bigCard-detailTable {
  font-size: 14px;
}
.desktop-shortCard-container-bigCard-imageBlock {
  position: relative;
}
.dfirst {
  left: 5px;
}

.desktop-shortCard-container-bigCard-imageBlock-thumbs {
    height: 100%;
    width: 75px;
    overflow: hidden;
    position: absolute;
    /* border-right: 1px solid lightgray !important; */
}
.desktop-shortCard-container-bigCard .desktop-shortCard-container-bigCard-imageBlock-thumbs-first {
  right: 0px;
  top: 0px;
    height: 100%;
    width: 75px;
    overflow: hidden;
    position: absolute;
    /* border-left: 1px solid lightgray; */
}
.desktop-shortCard-container-bigCard .desktop-shortCard-container-bigCard-imageBlock-thumbs div {
/*  background-color: gray; */
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 0;
  right: -17px; /* Increase/Decrease this value for cross-browser compatibility */
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: scroll;


}
.desktop-shortCard-container-bigCard .desktop-shortCard-container-bigCard-imageBlock-thumbs-first div {
/*  background-color: gray; */
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 0;
  right: -17px; /* Increase/Decrease this value for cross-browser compatibility */
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: scroll;


}
.desktop-shortCard-container-bigCard .desktop-shortCard-container-bigCard-imageBlock-thumbs-first div {
/*  background-color: gray; */
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 0;
  right: -17px; /* Increase/Decrease this value for cross-browser compatibility */
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: scroll;


}

.maximize .shortCard-badge {
  display: none;
}
.desktop-shortCard-container-bigCard-regular .shortCardContainer {

}
.desktop-shortCard-container-bigCard-regular {

}
.desktop-shortCard-container-bigCard-zakaz {
  /*border: 5px solid #0DA3C4 !important;*/
}
.desktop-shortCard-container-bigCard-new {
  /*border: 1px solid #7A9F38 !important;*/
}
.desktop-shortCard-container-bigCard-sale {
  /*border: 1px solid #F20359 !important;*/
}

.desktop-shortCard-container-bigCard-noLine {
  background-color: lightgray;
  height: 0px;
}
.desktop-shortCard-container-bigCard-blueLine {
  background-color: #0DA3C4;
  color: white;
}
.desktop-shortCard-container-bigCard-greenLine {
  background-color: #7A9F38;
  height: 0px;
}
.desktop-shortCard-container-bigCard-redLine {
  background-color: #F20359;
  height: 0px;
}
.desktop-shortCard-container-bigCard-blueLine {
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;

}

.desktop-shortCard-container-bigCard-blueLine span:nth-of-type(2){
  float:right;
}


.desktop-shortCard-container-bigCard-imageBlock-thumbs {
  width: 75px;
  float: left;
}

.additionalToolbar-selectMode {
  text-align: right;
  float: none;
  width: 100%;
  clear:both;

}

.teaserItem-container {

}

.teaserItem-image {
  width: 150px;
  height: 150px;
}

.teaserItem-image img{
  max-width: 150px;
  max-height: 150px;
}

.checkoutPage-unauthorized-contacts fieldset {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-color: #FFA80D;
}

.checkoutPage-unauthorized-contacts {
  /*max-width: 50%;*/
  /*margin: auto auto;*/
}
.checkoutPage-unauthorized-contacts fieldset input {
  width: 100%;
}

.checkoutPage-unauthorized-contacts fieldset .checkoutPage-radiogroup input {
    width: auto;
}
.checkoutPage-unauthorized-contacts fieldset {
  display: block;
}

.checkoutPage-unauthorized-contacts legend {
  /*display: flex;*/
}

.checkoutPage-unauthorized-contacts fieldset legend span {
   word-break: keep-all;
   white-space: nowrap;
}
.checkoutPage-unauthorized-contacts fieldset legend input {
  width: 24px;
}
.checkoutPage-unauthorized-contacts fieldset legend {
  /*font-weight: bold;*/
}
.checkoutPage-unauthorized-contacts textarea {
  width: 100%;
  min-height: 50px;
}
.views-checkooutPage-dataArea {
  background-color: transparent;
}

.shortCard-shortCardContent {
    bottom: 55px;
    position: absolute;
    left: 0px;
    width: 100%;
    height: auto;
    display: block;
    color: black;
}

/**** Advanced Card detail table ****/

.detailTable {
    width: 100%;
    /* font-size: .7rem; */
}


.detailTable tr {

}

.detailTable td:nth-of-type(1) {
    font-weight: bold;
}

.detailTable td {
    text-align: left;
    /* padding-left: 1em; */
}

.detailTable td {
    border: none;
    padding: 3px 10px;
    padding-left: 1em;
}

.detailTable tbody tr:nth-child(odd) td {
    background-color: white !important;
}


.autocomplete-root {
  flex-grow: 1;
  /*height: 250px;*/
  min-width: 250px;
}

.autocomplete-root input {
  width: 100% !important;
}

.autocomplete-root fieldset {
  border: none;
}

.autocomplete-menu-root {
  max-height: 250px;
  overflow-y: scroll;
  max-width: 85vw;
}

.error-box {
  border: 1px solid rgb(255,0,0,.5);
}

.errorInput {
  /*background-color: aquamarine;*/
  border: 1px solid rgb(255,0,0,.5);
}

.checkout-information-block {
  text-align: center;
}

.flex-proportional-row {
  display: flex !important;
  justify-content: space-between;
}
.flex-proportional-row div {
  margin-top: 3px;
}

.cart-actions-pannel-select-variant-button {
  /*color: white !important;*/
}


.loginForm-innerInfoText {
  font-size: .8em;
}

form .error input {
  border: 1px solid red;
}

.login-form button {
  margin-top: 20px;
}

.login-form .standardInput .r {
  /* margin-top: 0px; */
}
.login-form .i {
  height: auto;
  box-shadow:   0px 2px 4px rgba(0, 0, 0, 0.5);
  background-color: #E9F6FE;
}

.login-page {
  max-width: 360px;
  padding: 5% 0;
  margin: 0 auto;
}
.form {
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  max-width: 360px;
  /* margin: 0 auto 100px; */
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15), 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f5f5f5;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #4CAF50;
  text-decoration: none;
}
.form .register-form {
  display: block;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}


.leaveCommentDialog .button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.leaveCommentDialog button {
  width: 49%;
}
.leaveCommentDialog .MuiPaper-root {
  padding: 10px 10px;
}

@font-face {
  font-family: "OPT";
  src: url("./assets/fonts/19274.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

.discountBlock {
  font-family: OPT;
  font-size: 100px;
  /*font-weight: bold;*/
  color: #fa6054;
  color: #F20359;;
  transform: rotate(-20deg);
  /*margin-bottom: 4rem;*/
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  bottom: 100px;
  -webkit-text-stroke: 1px white;
  text-shadow:
          1px 1px 0 white,
          -1px 1px 0 white,
          1px -1px 0 white,
          -1px -1px 0 white;
}
@media all and (max-width: 600px) {
  .discountBlock {
    font-size: 100px;
    bottom: 40px;
  }
}

@media all and (min-width: 601px) {
  .discountBlock {
    font-size: 100px;
    bottom: 0px;
  }
}

@media all and (min-width: 1024px) {
  .discountBlock {
    font-size: 60px;
    bottom: 30px;
  }
}
